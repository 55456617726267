import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";
import { ChildrenProps } from "src/models/ChildrenPropModel";

type Props = ChildrenProps & {
    featureName: (typeof featureFlags)[keyof typeof featureFlags];
};

export const FeatureFlag = ({ featureName, children }: Props) => {
    const isFeatureEnabled = useFeatureFlag(featureName);

    return isFeatureEnabled ? children : null;
};
