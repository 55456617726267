import { useEffect } from "react";

import { useSwipeable } from "./Swipeable.hooks";
import { SwipeableContainerRightStyled, SwipeableElementStyled, WrapperStyled } from "./Swipeable.styles";

type Props = {
    children: React.ReactNode;
    contentRight?: React.ReactNode;
    closeOnClick?: boolean;
    closeOnTouchStart?: boolean;
};

export const Swipeable = ({ children, contentRight, closeOnClick, closeOnTouchStart }: Props) => {
    const { isOpened, swipableRef, containerRightRef, handleClose, handleTouchStart, handleTouchMove, handleTouchEnd } = useSwipeable();

    useEffect(() => {
        if (closeOnTouchStart) {
            const handleCloseOnTouchStart = (e: TouchEvent) => {
                if (swipableRef?.current && !swipableRef.current.contains(e.target as Node)) {
                    handleClose();
                }
            };

            if (isOpened) {
                document.addEventListener("touchstart", handleCloseOnTouchStart);
            } else {
                document.removeEventListener("touchstart", handleCloseOnTouchStart);
            }

            return () => {
                document.removeEventListener("touchstart", handleCloseOnTouchStart);
            };
        }
    }, [isOpened, swipableRef, closeOnTouchStart, handleClose]);

    useEffect(() => {
        if (closeOnClick) {
            if (isOpened) {
                document.addEventListener("mouseup", handleClose);
            } else {
                document.removeEventListener("mouseup", handleClose);
            }

            return () => {
                document.removeEventListener("mouseup", handleClose);
            };
        }
    }, [isOpened, closeOnClick, handleClose]);

    return (
        <WrapperStyled data-testid="swipeable" ref={swipableRef} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
            <SwipeableElementStyled>{children}</SwipeableElementStyled>
            {contentRight && <SwipeableContainerRightStyled ref={containerRightRef}>{contentRight}</SwipeableContainerRightStyled>}
        </WrapperStyled>
    );
};
