import { Spacing, TooltipRich as TooltipRichSCL } from "@secuis/ccp-react-components";
import { type PropsWithChildren } from "react";
import { ITooltip } from "react-tooltip";
import styled from "styled-components";

const TooltipRichWrapper = styled.div`
    .react-tooltip {
        padding: ${Spacing.XS}px ${Spacing.S}px;
        width: max-content;

        & > div > div:first-child {
            display: none;
        }
    }
`;

type TooltipRichExtendedProps = {
    title?: string;
    anchorSelect: string;
};

export const TooltipRich = ({ title = "", anchorSelect, children, ...rest }: PropsWithChildren<ITooltip & TooltipRichExtendedProps>) => (
    <TooltipRichWrapper>
        <TooltipRichSCL anchorSelect={anchorSelect} title={title} borderRadius="XXS" backgroundColor="accent" textColor="dark" {...rest}>
            {children}
        </TooltipRichSCL>
    </TooltipRichWrapper>
);
