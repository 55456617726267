import { useSelector } from "react-redux";
import { useSqlQuery } from "src/sql/hooks";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { fetchIncidentsQuery, parseIncidentsQueryResult } from "../../IncidentMatrix.queries";
import { CategoryIncidentCountPerSite } from "../../IncidentMatrix.types";

type UseFetchIncidentsResult = {
    incidents: CategoryIncidentCountPerSite;
    isLoading: boolean;
};

export const useFetchIncidents = (siteIds: string[], categories: string[], level: number): UseFetchIncidentsResult => {
    const { queryResult: incidents, isLoading } = useFetchIncidentsQuery(siteIds, categories, level);

    return {
        incidents,
        isLoading,
    };
};

const useFetchIncidentsQuery = (siteIds: string[], categories: string[], level: number) => {
    const startDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const endDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const queryParams = { startDate, endDate, siteIds, categories, level };

    const { isLoading, queryResult } = useSqlQuery(fetchIncidentsQuery, parseIncidentsQueryResult, queryParams);

    return { queryResult, isLoading };
};
