import { Headline, Icon, Stack } from "@secuis/ccp-react-components";
import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";

import { HeaderTooltip } from "./HeaderTooltip";

type MatrixHeaderProps = {
    title: string;
    tooltip: {
        categories: string[];
        categoryLevel: number;
    };
};

export const MatrixHeader = ({ title, tooltip }: MatrixHeaderProps) => {
    const { isMobileLayout } = useInsightsPageContext();

    return (
        <Stack>
            <Stack mb="M" mr="XS">
                <Headline bold={true}>{title}</Headline>
            </Stack>
            {!isMobileLayout && (
                <>
                    <Icon variant="Info" className="incident-matrix-header-target" />
                    <HeaderTooltip categories={tooltip.categories} categoryLevel={tooltip.categoryLevel} anchorSelect=".incident-matrix-header-target" />
                </>
            )}
        </Stack>
    );
};
