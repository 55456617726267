import { useMemo } from "react";
import { WorkerExecResult } from "src/models/DbModel";
import { CategoryLevel1, CategoryLevel1Keys } from "src/models/ReportCategoryModel";
import { useSqlQuery } from "src/sql/hooks";
import {
    getParameterizedArray,
    getReportQueryTimeRangeCondition,
    getSqlStringifiedArrayFromObject,
    WithSiteZoneQueryParams,
} from "src/sql/scripts/queryUtilities";
import { useFilteredSites } from "src/store/insights/FilterHooks";

const getIncidentCategoriesCountQuery = ({
    siteIds,
    startDate,
    endDate,
    withSiteZone = false,
}: { siteIds: string[]; startDate: Date; endDate: Date } & WithSiteZoneQueryParams) => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);

    return {
        sql: `
            SELECT category_level1 as category, count(*) as incidentsCount
            FROM reports
            WHERE
                category_level1 IS NOT NULL
                AND category_level1 <> ""
                AND category_level1 <> "${CategoryLevel1Keys.internalSecuritas}"
                AND ${getReportQueryTimeRangeCondition(startDate, endDate, withSiteZone)}
                AND location_id IN (${stringifiedSites})
            GROUP BY category_level1
        `,
        params: {
            ...siteIdsParams,
        },
    };
};

const parseIncidentCategoriesCountResult = ({ results }: WorkerExecResult): Partial<Record<CategoryLevel1, number>> => {
    const output: Partial<Record<CategoryLevel1, number>> = {};
    results[0]?.values?.forEach(([category, value]: [CategoryLevel1, number]) => (output[category] = value));

    return output;
};

export const useIncidentCategoriesCount = (startDate: Date, endDate: Date) => {
    const { siteIds } = useFilteredSites();
    const params = useMemo(() => ({ startDate, endDate, siteIds }), [startDate, endDate, siteIds]);

    return useSqlQuery(getIncidentCategoriesCountQuery, parseIncidentCategoriesCountResult, params);
};
