import { TouchEvent, useCallback, useRef, useState } from "react";

import { SWIPE_COEFFICIENT, SWIPE_UNCERTAINTY_THRESHOLD } from "./Swipeable.constants";
import { getTransformMatrix, getTxFromTransform } from "./Swipeable.helpers";

export const useSwipeable = () => {
    const [isOpened, setIsOpened] = useState(false);
    const [contentRightWidth, setContentRightWidth] = useState(0);
    const [styleTxStart, setStyleTxStart] = useState<number | null>(null);
    const [touchStart, setTouchStart] = useState<number | null>(null);
    const swipableRef = useRef<HTMLDivElement>(null);
    const containerRightRef = useRef<HTMLDivElement>(null);
    const hasRequiredRefs = Boolean(swipableRef?.current && containerRightRef?.current);

    const resetPositionState = useCallback(() => {
        setTouchStart(null);
        setStyleTxStart(null);
        setContentRightWidth(0);
    }, [setTouchStart, setStyleTxStart, setContentRightWidth]);

    const updateStyleTransform = useCallback(
        (tx: number) => {
            if (hasRequiredRefs) {
                swipableRef.current.style.transform = getTransformMatrix(tx);
            }
        },
        [hasRequiredRefs],
    );

    const handleClose = useCallback(() => {
        if (hasRequiredRefs && isOpened) {
            setIsOpened(false);
            updateStyleTransform(0);
            resetPositionState();
        }
    }, [hasRequiredRefs, isOpened, resetPositionState, updateStyleTransform]);

    const handleTouchStart = useCallback(
        (e: TouchEvent<HTMLDivElement>) => {
            if (!hasRequiredRefs) {
                return;
            }

            const currentTransformX = getTxFromTransform(swipableRef.current.style.transform);

            setStyleTxStart(currentTransformX);
            setTouchStart(e.targetTouches[0].clientX);
            setContentRightWidth(containerRightRef.current.clientWidth);
        },
        [hasRequiredRefs],
    );

    const handleTouchMove = useCallback(
        (e: TouchEvent<HTMLDivElement>) => {
            const touchEnd = e.targetTouches[0].clientX;

            if (!hasRequiredRefs || !touchStart || !touchEnd || !contentRightWidth) {
                return;
            }

            const distance = touchStart - touchEnd;
            const newTransformX = Math.min(styleTxStart - distance, 0);
            const isUncertainSwipe = Math.abs(distance) < SWIPE_UNCERTAINTY_THRESHOLD;
            const isSwipeCompleted = Math.abs(newTransformX) > contentRightWidth;

            if (isUncertainSwipe || isSwipeCompleted) {
                return;
            }

            e.stopPropagation();

            updateStyleTransform(newTransformX);
        },
        [hasRequiredRefs, touchStart, contentRightWidth, styleTxStart, updateStyleTransform],
    );

    const handleTouchEnd = useCallback(() => {
        if (!hasRequiredRefs || !touchStart || !contentRightWidth) {
            return;
        }

        const currentTransformX = getTxFromTransform(swipableRef.current.style.transform);
        const isSwipeCompleted = Math.abs(currentTransformX) > contentRightWidth * SWIPE_COEFFICIENT;

        setIsOpened(isSwipeCompleted);
        updateStyleTransform(isSwipeCompleted ? -contentRightWidth : 0);
        resetPositionState();
    }, [hasRequiredRefs, touchStart, contentRightWidth, resetPositionState, updateStyleTransform]);

    return {
        isOpened,
        swipableRef,
        containerRightRef,
        handleClose,
        handleTouchStart,
        handleTouchMove,
        handleTouchEnd,
    };
};
