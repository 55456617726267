import _orderBy from "lodash/orderBy";
import { WorkerExecResult } from "src/models/DbModel";
import { CategoryLevel1Keys } from "src/models/ReportCategoryModel";
import {
    getParameterizedArray,
    getReportQueryTimeRangeCondition,
    getSqlStringifiedArrayFromObject,
    WithSiteZoneQueryParams,
} from "src/sql/scripts/queryUtilities";

import { SiteEventsData } from "./SiteEventsWidget.types";

export const getSiteEventsCountQuery = ({
    siteIds,
    startDate,
    endDate,
    withSiteZone = false,
}: {
    siteIds: string[];
    startDate: Date;
    endDate: Date;
    categories: string[];
} & WithSiteZoneQueryParams) => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);

    return {
        sql: `
            SELECT location_id, category_level1, COUNT(*) as eventsCount
            FROM reports
            WHERE
                category_level1 IS NOT NULL
                AND category_level1 <> ""
                AND category_level1 <> "${CategoryLevel1Keys.internalSecuritas}"
                AND ${getReportQueryTimeRangeCondition(startDate, endDate, withSiteZone)}
                AND location_id IN (${stringifiedSites})
            GROUP BY location_id, category_level1
            ORDER BY eventsCount DESC
        `,
        params: {
            ...siteIdsParams,
        },
    };
};

export const parseSiteEventsResult = ({ results }: WorkerExecResult): SiteEventsData => {
    const values = results[0]?.values;

    if (!values?.length) {
        return [];
    }

    const result = values.reduce((acc, [locationId, category, count]) => {
        if (!acc[locationId]) {
            acc[locationId] = {
                locationId: locationId,
                totalCount: 0,
                categories: {},
            };
        }

        acc[locationId].totalCount += count;
        acc[locationId].categories[category] = count;

        return acc;
    }, {});

    return _orderBy(result, "totalCount", "desc");
};
