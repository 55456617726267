import { difference } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CategoryLevel1 } from "src/models/ReportCategoryModel";

import { UserPreference } from "../../../../models/UserModel";
import { useSetUserPreference, useUserPreference } from "../../../../store/user/UserHooks";
import { REPORT_CATEGORY_ICON_MAP } from "../../shared/constants";
import { SUMMARY_REPORT_CATEGORIES } from "../shared/constants";
import { useSummaryPeriod } from "../shared/hooks";
import { useIncidentCategoriesCount } from "../shared/hooks/useIncidentCategoriesCount";
import { CategoryStats } from "./IncidentCategoriesWidget.types";

export const useIncidentCategoriesWidget = () => {
    const setUserPreference = useSetUserPreference();
    const { t } = useTranslation();
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const [userPreference] = useUserPreference(UserPreference.IncidentsSummaryCategories);
    const userCategories = useMemo(() => userPreference?.split(",") as CategoryLevel1[] | undefined, [userPreference]);
    const [activeCategories, setActiveCategories] = useState<CategoryLevel1[]>(userCategories ?? []);
    const { isLoading: isLoadingCurrent, queryResult: currentValues } = useIncidentCategoriesCount(currentPeriod.start, currentPeriod.end);
    const { isLoading: isLoadingPrevious, queryResult: oldValues } = useIncidentCategoriesCount(previousPeriod.start, previousPeriod.end);

    const updateActiveCategories = useCallback(
        (categories: CategoryLevel1[]) => {
            const userCategoriesHaveChanged = difference(userCategories ?? [], categories).length || difference(categories, userCategories ?? []).length;

            setActiveCategories(categories);

            if (userCategoriesHaveChanged) {
                setUserPreference(UserPreference.IncidentsSummaryCategories, categories.join(","));
            }
        },
        [setUserPreference],
    );

    const categoriesStats: CategoryStats[] = useMemo(
        () =>
            SUMMARY_REPORT_CATEGORIES.filter((category) => activeCategories.includes(category))
                .map(
                    (category) =>
                        ({
                            id: category,
                            icon: REPORT_CATEGORY_ICON_MAP[category],
                            value: currentValues?.[category] ?? 0,
                            previousValue: oldValues?.[category] ?? 0,
                        }) satisfies Partial<CategoryStats>,
                )
                .map(
                    (stat) =>
                        ({
                            ...stat,
                            title: t(`incident.category.${stat.id}`),
                            label: t("common.event", { count: stat.value }),
                        }) satisfies CategoryStats,
                ),
        [t, currentValues, oldValues, activeCategories],
    );

    return {
        categoriesStats,
        isLoading: isLoadingCurrent || isLoadingPrevious,
        updateActiveCategories,
        initCategories: userCategories,
    };
};
