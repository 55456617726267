import { useTranslation } from "react-i18next";

import { QaProps, StyleProps } from "../../../../models";
import { CategoriesDropdown, StatsList, Widget } from "../shared/components";
import { useIncidentCategoriesWidget } from "./IncidentCategoriesWidget.hooks";

type Props = QaProps & StyleProps;

export const IncidentCategoriesWidget = (props: Props) => {
    const { categoriesStats, initCategories, isLoading, updateActiveCategories } = useIncidentCategoriesWidget();
    const { t } = useTranslation();
    const noActiveCategories = !categoriesStats.length;

    return (
        <Widget
            {...props}
            title={t("insights.summary.incidentCategoriesWidget.title")}
            topRightCornerSection={
                <div data-testid="categories-dropdown">
                    <CategoriesDropdown initValue={initCategories} onChange={updateActiveCategories} />
                </div>
            }
            isEmpty={noActiveCategories}
            emptyStateInfo={t("common.adjustTheFilter")}
            headerSeparator={false}
        >
            <StatsList stats={categoriesStats} isLoading={isLoading} spacing={0} />
        </Widget>
    );
};
