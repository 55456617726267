import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatDate } from "src/helpers/date";
import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";

import { Column } from "../../../../components/shared/Table/Table";
import { sortByOrder } from "../../../../helpers/ArrayHelper";
import { CategoryLevel1Keys } from "../../../../models/ReportCategoryModel";
import { TABLE_TOTAL_KEY } from "../../../../store/insights/InsightsModel";
import { DeviationKeys, getDataTimeRange } from "../../../../store/insights/PatrolDeviations/PatrolDeviationHelpers";
import * as PatrolDeviationsSelectors from "../../../../store/insights/PatrolDeviations/PatrolDeviationsSelectors";
import { DataTimeRange } from "../../../../store/insights/PatrolDeviations/PatrolDeviationsTypes";
import {
    chartCellStyles,
    dataCellStyles,
    dataHeaderStyles,
    getCategoryCellStyles,
    getCategoryHeaderStyles,
    getExpandButtonCellStyles,
    getExpandButtonHeaderStyles,
    StyledTable,
    totalAmountCellStyles,
} from "./DeviationsTable.styles";
import { CategoryCell, Cell, ChartCell, ExpandCell } from "./DeviationsTableCells";

const order = [
    CategoryLevel1Keys.criminalActOrSuspiciousBehavior,
    CategoryLevel1Keys.facility,
    CategoryLevel1Keys.healthAndSafety,
    "internal_securitas",
    CategoryLevel1Keys.peopleAndAssets,
    null,
    TABLE_TOTAL_KEY,
];

type Props = {
    requestLevel2: (cat: string) => void;
    selectedStartDate: Date;
    selectedEndDate: Date;
    isFirstMonthFull: boolean;
    isLastMonthFull: boolean;
};

const TABLE_META_KEYS = [DeviationKeys.category, DeviationKeys.children, DeviationKeys.categoryLabel];

export const DeviationsTable = ({ requestLevel2, selectedStartDate, selectedEndDate, isFirstMonthFull, isLastMonthFull }: Props) => {
    const { t } = useTranslation();
    const { isMobileLayout } = useInsightsPageContext();
    const isTablet = useHasMaxWidth(Breakpoints.S);
    const patrolDeviations = useSelector(PatrolDeviationsSelectors.getPatrolDeviations);
    const range = getDataTimeRange(selectedStartDate, selectedEndDate);
    const dateTimeKeys = Object.keys(patrolDeviations[0]).filter((key) => !TABLE_META_KEYS.includes(DeviationKeys[key]));
    const hasMobileLayout = isMobileLayout || (isTablet && range !== DataTimeRange.day && range !== DataTimeRange.month);

    const getColumnHeader = useCallback(
        (header: string, index: number) => {
            let columnHeader = header;
            if (isMobileLayout && range === DataTimeRange.week) {
                columnHeader = header.split(" ")[0];
            }
            if (isMobileLayout && range === DataTimeRange.halfYear) {
                columnHeader = header.substring(0, 1);
            }
            // add * to first and last months if not first day of month
            if (range === DataTimeRange.halfYear && index === 0 && !isFirstMonthFull) {
                columnHeader = `${columnHeader}*`;
            }
            if (range === DataTimeRange.halfYear && index === dateTimeKeys.length - 1 && !isLastMonthFull) {
                columnHeader = `${columnHeader}*`;
            }
            return columnHeader;
        },
        [isMobileLayout, range, isFirstMonthFull, dateTimeKeys.length, isLastMonthFull],
    );

    const dateTimeColumns: Column[] =
        range === DataTimeRange.anyRange
            ? [
                  {
                      key: "chartData",
                      header: `${formatDate(selectedStartDate)} - ${formatDate(selectedEndDate)}`,
                      id: "chart",
                      Cell: ChartCell,
                      style: chartCellStyles,
                      headerStyle: dataHeaderStyles,
                  },
                  {
                      key: "totalAmount",
                      header: t("insights.siteTasks.category.deviations"),
                      id: "totalAmount",
                      Cell: Cell,
                      style: totalAmountCellStyles,
                      headerStyle: dataHeaderStyles,
                  },
              ]
            : dateTimeKeys.map((d, index) => ({
                  header: getColumnHeader(d, index),
                  key: d,
                  id: d,
                  Cell: Cell,
                  style: dataCellStyles(index + 1, range, hasMobileLayout),
                  headerStyle: dataHeaderStyles,
              }));

    const columns: Column[] = hasMobileLayout
        ? [
              {
                  header: t("insights.patrolDeviations.table.category"),
                  key: "category",
                  id: "cat-id",
                  Cell: ({ row }) => <CategoryCell hasMobileLayout={hasMobileLayout} row={row} />,
                  style: hasMobileLayout ? getCategoryCellStyles(range) : null,
                  headerStyle: getCategoryHeaderStyles(hasMobileLayout),
              },
              {
                  key: "category",
                  id: "expand-button",
                  Cell: ({ row, onClick, ...rest }) => <ExpandCell row={row} onClick={onClick} {...rest} requestLevel2={requestLevel2} />,
                  style: getExpandButtonCellStyles(range),
                  headerStyle: getExpandButtonHeaderStyles(hasMobileLayout),
              },
              ...dateTimeColumns,
          ]
        : [
              {
                  header: t("insights.patrolDeviations.table.category"),
                  key: "category",
                  id: "cat-id",
                  Cell: ({ row }) => <CategoryCell hasMobileLayout={hasMobileLayout} row={row} />,
                  style: hasMobileLayout ? getCategoryCellStyles(range) : null,
                  headerStyle: getCategoryHeaderStyles(hasMobileLayout),
              },
              ...dateTimeColumns,
              {
                  key: "category",
                  id: "expand-button",
                  Cell: ({ row, onClick, ...rest }) => <ExpandCell row={row} onClick={onClick} {...rest} requestLevel2={requestLevel2} />,
                  style: getExpandButtonCellStyles(range),
                  headerStyle: getExpandButtonHeaderStyles(hasMobileLayout),
              },
          ];

    return (
        <StyledTable
            hasMobileLayout={hasMobileLayout}
            range={range}
            expandable
            columnCount={columns.length}
            data={sortByOrder(patrolDeviations, order, "category")}
            columns={columns}
        />
    );
};
