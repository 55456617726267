import { Box, GraphPalette, Icon, IconType, Palette, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useAutoTranslationWhenNotTranslated } from "src/helpers/autoTranslations/hooks";

import { Circle, Dash, Line, LineWrapper, StackStyled } from "./TimelineEvent.styles";

type Props = {
    iconType: IconType;
    status: string;
    lineColor: GraphPalette | Palette;
    shouldAutoTranslate: boolean;
    dateTime?: string;
};

export const TimelineEvent = ({ iconType, dateTime, status, lineColor, shouldAutoTranslate }: Props) => {
    const { translatedText } = useAutoTranslationWhenNotTranslated(status, shouldAutoTranslate);
    const { t } = useTranslation();
    const statusText = shouldAutoTranslate ? translatedText : t(status);

    return (
        <Box>
            <Stack alignItems="center" gap="XS" flexWrap="nowrap">
                <Stack alignItems="center">
                    <Circle style={iconType === "LeaveBuilding" ? { paddingLeft: "5px" } : null}>
                        <Icon variant={iconType} color="neutral" />
                    </Circle>
                    <Dash />
                </Stack>
                <StackStyled>
                    {dateTime && (
                        <Text small bold>
                            {dateTime}
                        </Text>
                    )}
                    <Text small>{statusText}</Text>
                </StackStyled>
            </Stack>
            {lineColor && (
                <LineWrapper>
                    <Line background={lineColor} />
                </LineWrapper>
            )}
        </Box>
    );
};
