import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const TrendingEventsContainer = styled(Stack)`
    direction: column;
    gap: ${Spacing.M}px;

    @media (max-width: ${Breakpoints.M}) {
        gap: ${Spacing.S}px;
    }
`;
