import { addDays, endOfDay, isToday } from "../helpers/date";

export const parseEndDateIfItsToday = (selectedEndDate: Date | null): Date | null => {
    // NOTE: While filtering reports based on site local time, user's "today"
    // may not include some reports taking place in the future date (from site point of view).
    // https://secsisip.atlassian.net/browse/GLOB-4793
    // https://secsisip.atlassian.net/browse/GLOB-4778

    return selectedEndDate && isToday(selectedEndDate) ? endOfDay(addDays(new Date(), 1)) : selectedEndDate;
};
