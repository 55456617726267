import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLettersFromNumber } from "src/helpers/graphs";
import type { CategoryLevel1 } from "src/models/ReportCategoryModel";
import { useSqlQuery } from "src/sql/hooks";
import { useFilteredSites } from "src/store/insights/FilterHooks";
import { useAuthorizedLocations } from "src/store/locations/LocationsHooks";

import type { GraphData } from "../shared/components/Graph/Graph.types";
import { useSummaryPeriod } from "../shared/hooks";
import type { WidgetViewMode } from "../Summary.types";
import { filterSiteEventsCountData } from "./SiteEventsWidget.helpers";
import { getSiteEventsCountQuery, parseSiteEventsResult } from "./SiteEventsWidget.queries";
import { SiteEventsData, SiteEventsGraphData } from "./SiteEventsWidget.types";
import { useSetUserPreference, useUserPreference } from "../../../../store/user/UserHooks";
import { UserPreference } from "../../../../models/UserModel";
import { difference } from "lodash";

const CHART_BAR_COUNT = 5;

export const useSiteEventsWidget = () => {
    const [viewMode, setViewMode] = useState<WidgetViewMode>("most");
    const setUserPreference = useSetUserPreference();
    const [userPreference] = useUserPreference(UserPreference.SiteEventsSummaryCategories);
    const userCategories = useMemo(() => userPreference?.split(",") as CategoryLevel1[] | undefined, [userPreference]);
    const [activeCategories, setActiveCategories] = useState<CategoryLevel1[]>(userCategories ?? []);
    const { currentPeriod } = useSummaryPeriod();
    const { siteEventsCountData, isLoading } = useSiteEventsCountQuery(currentPeriod.start, currentPeriod.end);
    const siteEventsCountDataFiltered = filterSiteEventsCountData(siteEventsCountData, activeCategories);
    const siteEventsGraphData = useSiteEventsGraphData(siteEventsCountDataFiltered, CHART_BAR_COUNT);
    const isSwitcherVisible = siteEventsCountDataFiltered?.length > CHART_BAR_COUNT;
    const isCategoriesDropdownVisible = siteEventsCountData?.length > 0;
    const graphData = siteEventsGraphData[viewMode];

    useEffect(() => {
        if (!isSwitcherVisible) {
            setViewMode("most");
        }
    }, [isSwitcherVisible]);

    const handleSetViewMode = (mode: WidgetViewMode) => {
        setViewMode(mode);
    };

    const updateActiveCategories = useCallback(
        (newCategories: CategoryLevel1[]) => {
            setActiveCategories(newCategories);
            const changedSinceSaving = difference(userCategories ?? [], newCategories).length || difference(newCategories, userCategories ?? []).length;

            if (changedSinceSaving) {
                setUserPreference(UserPreference.SiteEventsSummaryCategories, newCategories.join(","));
            }
        },
        [setActiveCategories, setUserPreference, userCategories],
    );

    return {
        viewMode,
        graphData: graphData,
        isSwitcherVisible,
        isCategoriesDropdownVisible,
        initCategories: userCategories,
        isLoading,
        isEmpty: !isLoading && !siteEventsCountDataFiltered?.length,
        setViewMode: handleSetViewMode,
        updateActiveCategories,
    };
};

const useSiteEventsCountQuery = (startDate: Date, endDate: Date) => {
    const { siteIds } = useFilteredSites();
    const queryParams = { siteIds, startDate, endDate };
    const { isLoading, queryResult } = useSqlQuery(getSiteEventsCountQuery, parseSiteEventsResult, queryParams);

    return { siteEventsCountData: queryResult, isLoading };
};

const useSiteEventsGraphData = (siteEventsCountData?: SiteEventsData, resultCount = 5): SiteEventsGraphData => {
    const { siteObjects } = useAuthorizedLocations();
    const { t } = useTranslation();

    const siteEventsGraphData = useMemo(() => {
        const getSiteName = (locationId: string) => {
            const site = siteObjects.find((x) => x.id === locationId);
            return site?.displayName || "";
        };

        const getGraphData = (data: SiteEventsData): GraphData => {
            return data.map((item, index) => {
                const siteName = getSiteName(item.locationId);
                const name = getLettersFromNumber(index);

                return {
                    key: name,
                    name: name,
                    value: item.totalCount,
                    label: siteName,
                    secondLabel: `${item.totalCount}\u00A0${item.totalCount === 1 ? t("insights.overview.event") : t("insights.overview.event_plural")}`,
                    tooltip: [siteName],
                };
            });
        };

        if (!siteEventsCountData?.length) {
            return {
                most: { data: [] },
                least: { data: [] },
            };
        }

        const mostData = getGraphData(siteEventsCountData.slice(0, resultCount));
        const leastData = getGraphData(siteEventsCountData.slice(-resultCount).reverse());

        return {
            most: {
                data: mostData,
            },
            least: {
                data: leastData,
            },
        };
    }, [siteEventsCountData, resultCount, siteObjects, t]);

    return siteEventsGraphData;
};
