import { useBooleanFlagValue } from "@openfeature/react-sdk";
import { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { featureFlags } from "../../data/featureFlags";
import FeatureFlagsActions from "../../store/featureFlags/FeatureFlagsActions";
import FeatureFlagsSelectors from "../../store/featureFlags/FeatureFlagsSelectors";
import { RequestStatus } from "../../store/RequestStatus";
import { Loading } from "../shared/Loading";

// useBooleanFlagValue tends to overregister event listeners when reused in hooks
// in such cases, feature flag can be stored in store and accessed with selector in hooks
export const FeatureFlagResolver = ({ children }: { children?: ReactNode }) => {
    const dispatch = useDispatch();
    const isAssignmentReportTypeEnabled = useBooleanFlagValue(featureFlags.assignmentReports, false);
    const isCalloutReportTypeEnabled = useBooleanFlagValue(featureFlags.calloutReports, false);
    const isVehicleInspectionReportEnabled = useBooleanFlagValue(featureFlags.vehicleInspectionReports, false);
    const isKpiSummaryEnabled = useBooleanFlagValue(featureFlags.kpiSummary, false);
    const isIncidentMatrixEnabled = useBooleanFlagValue(featureFlags.incidentMatrix, false);
    const isIncidentMatrixLevel2and3Enabled = useBooleanFlagValue(featureFlags.incidentMatrixLevel2and3, false);
    const areSiteTimezonesEnabled = useBooleanFlagValue(featureFlags.sitesTimezones, false);
    const isCalloutTimelineEnabled = useBooleanFlagValue(featureFlags.calloutTimeline, false);
    const featureFlagsStatus = useSelector(FeatureFlagsSelectors.selectFeatureFlagsStatus);
    const isDemoEnabled = useBooleanFlagValue(featureFlags.inMemoryDemoClient, false) || process.env.REACT_APP_INMEMORY_DEMO_ENABLED === "true";

    useEffect(() => {
        dispatch(
            FeatureFlagsActions.setFeatureFlags({
                [featureFlags.assignmentReports]: isAssignmentReportTypeEnabled,
                [featureFlags.calloutReports]: isCalloutReportTypeEnabled,
                [featureFlags.vehicleInspectionReports]: isVehicleInspectionReportEnabled,
                [featureFlags.kpiSummary]: isKpiSummaryEnabled,
                [featureFlags.incidentMatrix]: isIncidentMatrixEnabled,
                [featureFlags.incidentMatrixLevel2and3]: isIncidentMatrixLevel2and3Enabled,
                [featureFlags.sitesTimezones]: areSiteTimezonesEnabled,
                [featureFlags.calloutTimeline]: isCalloutTimelineEnabled,
                [featureFlags.inMemoryDemoClient]: isDemoEnabled,
            }),
        );
    }, [
        dispatch,
        isCalloutReportTypeEnabled,
        isKpiSummaryEnabled,
        isIncidentMatrixEnabled,
        isIncidentMatrixLevel2and3Enabled,
        isVehicleInspectionReportEnabled,
        isAssignmentReportTypeEnabled,
        areSiteTimezonesEnabled,
        isCalloutTimelineEnabled,
        isDemoEnabled,
    ]);

    if (featureFlagsStatus !== RequestStatus.success) {
        return <Loading size="L" />;
    }

    return children;
};
