import { Box, Stack, Title } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { REPORT_CATEGORY_ICON_MAP } from "../../../../shared/constants";
import { BackButton } from "./BackButton";
import { useBreadcrumbs } from "./Breadcrumbs.hooks";
import { IconStyled, StackStyled } from "./Breadcrumbs.styles";

type BreadcrumbsProps = {
    categoryKey: string;
};

export const Breadcrumbs = ({ categoryKey }: BreadcrumbsProps) => {
    const { t } = useTranslation();

    const matrixCategoryLevel = useSelector(InsightsSelectors.getIncidentMatrixLevel);
    const breadcrumbs = useBreadcrumbs(categoryKey);
    const categoryLevel1Icon = breadcrumbs.length > 0 ? REPORT_CATEGORY_ICON_MAP[breadcrumbs[0]] : null;

    return (
        <Box>
            {matrixCategoryLevel > 1 && <BackButton categoryKey={breadcrumbs[0]} />}
            <StackStyled alignItems="center">
                {categoryLevel1Icon && <IconStyled variant={categoryLevel1Icon} size="L" />}
                <Stack>
                    <Title>
                        {breadcrumbs.map((breadcrumbCategoryKey) => {
                            return <span key={breadcrumbCategoryKey}>{t(`incident.category.${breadcrumbCategoryKey}`)}</span>;
                        })}
                    </Title>
                </Stack>
            </StackStyled>
        </Box>
    );
};
