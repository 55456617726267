import { useFilteredSites } from "src/store/insights/FilterHooks";

import { useCategoryInfo, useMatrixPeriod } from "..";
import { useMatrixDeviationCount } from "./useMatrixDeviationCount";
import { useMatrixIncidentCount } from "./useMatrixIncidentCount";

type UseCountIncidentsAndDeviationsResult = {
    isLoading: boolean;
    currentPeriodCount: number;
    previousPeriodCount: number;
};

export const useCountIncidentsAndDeviations = (categoryKey: string): UseCountIncidentsAndDeviationsResult => {
    const { currentPeriod, previousPeriod } = useMatrixPeriod();
    const { categories, level } = useCategoryInfo(categoryKey);
    const { siteIds } = useFilteredSites();

    const {
        isLoading: isIncidentCountingLoading,
        currentPeriodIncidentCount,
        previousPeriodIncidentCount,
    } = useMatrixIncidentCount({ categories, level, siteIds, currentPeriod, previousPeriod });
    const {
        isLoading: isDeviationCountingLoading,
        currentPeriodDeviationsCount,
        previousPeriodDeviationsCount,
    } = useMatrixDeviationCount({ categories, level, siteIds, currentPeriod, previousPeriod });

    const isLoading = isIncidentCountingLoading || isDeviationCountingLoading;

    return {
        isLoading,
        currentPeriodCount: currentPeriodIncidentCount + currentPeriodDeviationsCount,
        previousPeriodCount: previousPeriodIncidentCount + previousPeriodDeviationsCount,
    };
};
