import { formatToRawDate } from "src/helpers/date";
import { useApiRequest } from "src/hooks/accessApi";

import { FetchDeviationsAPIResponse } from "../../IncidentMatrix.types";

type UseMatrixDeviationCountProps = {
    categories: string[];
    level: number;
    siteIds: string[];
    currentPeriod: { start: Date; end: Date };
    previousPeriod: { start: Date; end: Date };
};

type UseMatrixDeviationCountResult = {
    isLoading: boolean;
    currentPeriodDeviationsCount: number;
    previousPeriodDeviationsCount: number;
};

export const useMatrixDeviationCount = ({
    categories,
    level,
    siteIds,
    currentPeriod,
    previousPeriod,
}: UseMatrixDeviationCountProps): UseMatrixDeviationCountResult => {
    const { data: currentPeriodDeviationsData, isLoading: isCurrentPeriodLoading } = <FetchDeviationsAPIResponse>(
        useApiRequest("/v2/global/insights/tour-exc/monthly-location-category-summary", "POST", {
            locationIds: siteIds,
            categories: categories.map((category) => {
                return {
                    category,
                    level,
                };
            }),
            fromDate: formatToRawDate(currentPeriod.start),
            toDate: formatToRawDate(currentPeriod.end),
        })
    );

    const { data: previousPeriodDeviationsData, isLoading: isPreviousPeriodLoading } = <FetchDeviationsAPIResponse>(
        useApiRequest("/v2/global/insights/tour-exc/monthly-location-category-summary", "POST", {
            locationIds: siteIds,
            categories: categories.map((category) => {
                return {
                    category,
                    level,
                };
            }),
            fromDate: formatToRawDate(previousPeriod.start),
            toDate: formatToRawDate(previousPeriod.end),
        })
    );

    const isLoading = isCurrentPeriodLoading || isPreviousPeriodLoading;

    const currentPeriodDeviationsCount = Array.isArray(currentPeriodDeviationsData)
        ? currentPeriodDeviationsData.reduce((sum, deviation) => sum + deviation.count, 0)
        : 0;
    const previousPeriodDeviationsCount = Array.isArray(previousPeriodDeviationsData)
        ? previousPeriodDeviationsData.reduce((sum, deviation) => sum + deviation.count, 0)
        : 0;

    return {
        isLoading,
        currentPeriodDeviationsCount,
        previousPeriodDeviationsCount,
    };
};
