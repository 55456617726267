import { Breakpoints, ButtonText, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useInsightsTabs } from "src/pages/Insights/InsightsTabs/InsightsTabs.hooks";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { TextStyled } from "./BackButton.styles";

type BackButtonProps = {
    categoryKey: string;
};

export const BackButton = ({ categoryKey }: BackButtonProps) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { setActiveTab } = useInsightsTabs();

    const iconSize = isMobile ? "L" : undefined;
    const categoryNameTranslation = t(`incident.category.${categoryKey}`);

    const matrixCategoryLevel = useSelector(InsightsSelectors.getIncidentMatrixLevel);

    const buttonText =
        matrixCategoryLevel === 2
            ? t("incidentMatrix.table.backButton.allEvents")
            : `${categoryNameTranslation} ${t("incidentMatrix.table.backButton.events")}`;

    const onClickAction = () => {
        if (matrixCategoryLevel === 2) {
            navigate(`/insights`);
            setActiveTab(2);
        } else {
            navigate(`/insights/overview-events/${categoryKey}`);
        }
    };

    return (
        <Stack mb={["XL", "L", "S"]}>
            <ButtonText data-testid="back-navigation-button" icon="BackNavigation" iconSize={iconSize} onClick={onClickAction}>
                <TextStyled truncate>{buttonText}</TextStyled>
            </ButtonText>
        </Stack>
    );
};
