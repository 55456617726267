import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Breakpoints, Spacing, Title, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { SwitcherBanner } from "../../components/ClientSwitcher/SwitcherBanner";
import { InsightsDateFilter } from "../../components/Insights/Filter/InsightsDateFilter";
import { InsightsFilter } from "../../components/Insights/Filter/InsightsFilter";
import { SummaryChips } from "../../components/Insights/Filter/SummaryChips";
import { FilterIcon } from "../../components/shared/filters";
import { CollapsedFilterWrapper, ExpandablePanel } from "../../components/shared/filters/FilterToolbar.styles";
import { PageNames } from "../../models/tracking/pageView";
import { useDisplaySwitcherBanner } from "../../store/clientSwitcher/ClientSwitcherHooks";
import { useFiltersCount } from "../../store/insights/FilterHooks";
import { useSetInsightFiltersByParams } from "../../store/insights/InsightHooks";
import { useTrackPageVisit } from "../../store/tracking/TrackingHooks";
import { InsightsWrapper, MobileFilterIconWrapper, PageHeaderWrapper, PageWrapper, SwitcherBannerWrapper, TitleWrapper } from "./Common.styled";
import { useInsightsPageContext } from "./InsightsPageProvider";

type InsightsContentProps = {
    isOverlayMode?: boolean;
};

export const InsightsContent = ({ isOverlayMode = false }: InsightsContentProps) => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const isBannerVisible = useDisplaySwitcherBanner() && !isOverlayMode;
    const isSummaryEnabled = useFeatureFlag(featureFlags.kpiSummary);
    const selectedTab = useSelector(InsightsSelectors.getSelectedTab);
    const filtersCount = useFiltersCount();
    const { isMobileLayout, isFilterActive, setIsFilterActive } = useInsightsPageContext();

    const isSummaryTab = isSummaryEnabled && selectedTab === 0;

    useTrackPageVisit(PageNames.Insights);
    useSetInsightFiltersByParams();

    const closeFilter = () => setIsFilterActive(false);
    const openFilter = () => setIsFilterActive(true);

    const renderFilterIcon = () => <FilterIcon activeFiltersCount={filtersCount} onClick={openFilter} isActive={isFilterActive} />;

    const renderInsightsFilter = () => <InsightsFilter onClose={closeFilter} />;

    const renderInsightDateFilter = () => <InsightsDateFilter onCalendarOpen={closeFilter} />;

    return (
        <PageWrapper>
            {!isMobile && !isFilterActive && <CollapsedFilterWrapper>{renderFilterIcon()}</CollapsedFilterWrapper>}
            {!isMobile && isFilterActive && renderInsightsFilter()}
            {isMobile && isFilterActive && <ExpandablePanel>{renderInsightsFilter()}</ExpandablePanel>}
            <InsightsWrapper direction="column">
                {isBannerVisible && (
                    <SwitcherBannerWrapper isFilterActive={isFilterActive}>
                        <SwitcherBanner isMobileLayout={isMobileLayout} />
                    </SwitcherBannerWrapper>
                )}
                <PageHeaderWrapper isFilterActive={isFilterActive} paddingTop={isOverlayMode && Spacing.S}>
                    <TitleWrapper isOverlayMode={isOverlayMode}>
                        {!isOverlayMode && <Title data-testid="insights-title">{t("insights.title")}</Title>}
                        {!isMobileLayout && !isSummaryTab && renderInsightDateFilter()}
                        {isMobile && !isFilterActive && <MobileFilterIconWrapper isOverlayMode={isOverlayMode}>{renderFilterIcon()}</MobileFilterIconWrapper>}
                    </TitleWrapper>
                    {isMobileLayout && !isSummaryTab && !isOverlayMode && renderInsightDateFilter()}
                    {!isOverlayMode && <SummaryChips sitesCount={filtersCount} />}
                </PageHeaderWrapper>
                <Outlet />
            </InsightsWrapper>
        </PageWrapper>
    );
};
