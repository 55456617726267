import { Box, Icon, Stack, Text } from "@secuis/ccp-react-components";
import millify from "millify";
import { useTranslation } from "react-i18next";
import { TooltipRich } from "src/components/shared/TooltipRich";

import { CellWrapperStyled, TooltipContentStyled, TotalStyled } from "./OtherHeaderCell.styles";

type OtherHeaderCellProps = {
    totalCount: number;
};

export const OtherHeaderCell = ({ totalCount }: OtherHeaderCellProps) => {
    const { t } = useTranslation();

    return (
        <CellWrapperStyled disabled={true}>
            <Stack alignItems="center">
                <Stack>
                    <Box mr="XS">
                        <Text micro truncate={true} data-testid="matrix-table-data-category-name">
                            {t("incidentMatrix.table.otherColumn.header")}
                        </Text>
                    </Box>
                    <Icon variant="Info" className="incident-matrix-other-column-target" />
                </Stack>
            </Stack>
            <TooltipRich anchorSelect=".incident-matrix-other-column-target" place="top-end">
                <TooltipContentStyled>
                    <Text small bold>
                        {t("incidentMatrix.table.otherColumn.tooltip.contentDescription")}
                    </Text>
                    <Text small bold>
                        {t("incidentMatrix.table.otherColumn.tooltip.actionDescription")}
                    </Text>
                </TooltipContentStyled>
            </TooltipRich>
            <TotalStyled small color="secondary" data-testid="matrix-table-data-category-total">
                {t("incidentMatrix.table.total")}: {millify(totalCount)}
            </TotalStyled>
        </CellWrapperStyled>
    );
};
