import { Stack } from "@secuis/ccp-react-components";
import { useFeatureFlag } from "src/hooks/featureFlags";

import { BlueCards } from "../../../components/Insights/Cards/BlueCards";
import { featureFlags } from "../../../data/featureFlags";
import { GraphOverview } from "../Graphs/GraphOverview";
import { TrendingEvents } from "../Trends/TrendingEvents";
import { IncidentMatrix } from "./IncidentMatrix";

export const Events = () => {
    const isIncidentMatrixEnabled = useFeatureFlag(featureFlags.incidentMatrix);

    return isIncidentMatrixEnabled ? (
        <Stack direction="column" gap="M">
            <IncidentMatrix />
            <TrendingEvents />
            <BlueCards />
        </Stack>
    ) : (
        <GraphOverview />
    );
};
