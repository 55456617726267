import { InsightsContent } from "./InsightsContent";
import { InsightsPageProvider } from "./InsightsPageProvider";

type InsightsProps = {
    isOverlayMode?: boolean;
};

export const Insights = ({ isOverlayMode }: InsightsProps) => {
    return (
        <InsightsPageProvider>
            <InsightsContent isOverlayMode={isOverlayMode} />
        </InsightsPageProvider>
    );
};
