import { Breakpoints, Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const SectionWrapper = styled(Stack)<{ isFilterActive: boolean }>`
    background-color: ${Palette.Navy800};
    width: 100%;
    padding: ${Spacing.L}px;
    border-radius: ${Spacing.XXS}px;
    overflow-y: unset;

    @media (max-width: ${Breakpoints.S}) {
        padding: ${(props) => (props.isFilterActive ? `${Spacing.S}px` : `${Spacing.M}px`)};
    }

    @media (max-width: ${Breakpoints.XS}) {
        padding: ${Spacing.S}px;
    }
`;

export const SectionTitle = styled(Text)`
    font-size: 24px;
`;

export const TooltipContainer = styled(Stack)`
    gap: ${Spacing.XS}px;
    padding-top: ${Spacing.XS}px;
    align-items: baseline;
    @media (max-width: ${Breakpoints.XS}) {
        gap: ${Spacing.XXS}px;
        align-items: center;
    }
`;
// Temp solution for GLOB-3825
export const StyledSectionWrapper = styled(SectionWrapper)`
    margin-bottom: 84px;
`;
