export const featureFlags = {
    assignmentReports: "experiment-assignment-reports",
    calloutReports: "experiment-call-out-reports",
    vehicleInspectionReports: "release-vehicle-inspection-reports",
    autotranslations: "experiment-autotranslations",
    inMemoryDemoClient: "entitlement-in-memory-demo-client",
    viewSchedule: "release-view-schedule",
    kpiSummary: "release-kpi-summary",
    incidentMatrix: "release-incident-matrix",
    incidentMatrixLevel2and3: "release-incident-matrix-level-2-3",
    sitesTimezones: "release-sites-timezones",
    calloutTimeline: "release-callout-timeline",
};
