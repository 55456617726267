import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InsightsDateFilter } from "src/components/Insights/Filter/InsightsDateFilter";

import { IncidentMatrix } from "../Events/IncidentMatrix";
import { Breadcrumbs, TrendIndicatorBar } from "../Events/IncidentMatrix/components";
import { useCategoryInfo } from "../Events/IncidentMatrix/hooks";
import { useInsightsPageContext } from "../InsightsPageProvider";
import { useInsightsTabs } from "../InsightsTabs/InsightsTabs.hooks";
import { StackStyled } from "./OverviewEvents.styles";

export const OverviewEvents = () => {
    const { categoryKey } = useParams();
    const navigate = useNavigate();
    const { setActiveTab } = useInsightsTabs();

    const { isMobileLayout, setIsFilterActive } = useInsightsPageContext();

    const categoryInfo = useCategoryInfo(categoryKey);

    useEffect(() => {
        if (!categoryInfo) {
            navigate("/insights");
            setActiveTab(2);
        }
    }, [categoryInfo, navigate, setActiveTab]);

    if (!categoryInfo) return;

    return (
        <StackStyled direction="column" gap={isMobileLayout ? "S" : "M"}>
            <Breadcrumbs categoryKey={categoryKey} />
            {isMobileLayout && <InsightsDateFilter onCalendarOpen={() => setIsFilterActive(false)} />}
            <TrendIndicatorBar categoryKey={categoryKey} />
            <IncidentMatrix categoryKey={categoryKey} />
        </StackStyled>
    );
};
