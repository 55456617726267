import { Breakpoints, ButtonText, Spacing, Stack } from "@secuis/ccp-react-components";
import { CSSProperties } from "react";
import styled from "styled-components";

export const PageWrapper = styled(Stack)`
    flex-direction: row-reverse;
    width: 100%;
    height: 100%;

    @media (max-width: ${Breakpoints.XS}) {
        direction: column;
    }
`;

export const InsightsWrapper = styled(Stack)`
    flex: 1;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
`;

const SwitcherBannerWrapperMobileStyles = `
    width: 100%;
    gap: ${Spacing.L}px;
    padding: ${Spacing.XS}px;
`;

export const SwitcherBannerWrapper = styled(Stack)<{ isFilterActive?: boolean }>`
    @media (min-width: ${Breakpoints.XS}) {
        padding-left: 40px;
        padding-right: 40px;
    }

    @media only screen and (max-width: ${Breakpoints.M}) {
        padding-top: ${Spacing.M}px;
        padding-bottom: ${Spacing.M}px;
    }

    @media only screen and (max-width: ${Breakpoints.S}) {
        ${(props) => (props.isFilterActive ? SwitcherBannerWrapperMobileStyles : "")}
    }

    @media only screen and (max-width: ${Breakpoints.XS}) {
        ${SwitcherBannerWrapperMobileStyles}
    }
`;

export const MobileFilterIconWrapper = styled(Stack)<{ isOverlayMode?: boolean }>`
    display: none;

    @media (max-width: ${Breakpoints.XS}) {
        ${(props) =>
            props.isOverlayMode
                ? `
                    display: block;
                    position: absolute;
                    top: 20px;
                    right: ${Spacing.S}px;
                `
                : `
                    display: flex;
                    margin-left: auto;
                `}
    }
`;

const PageHeaderWrapperMobileStyles = `
    gap: ${Spacing.XS}px;
    margin-bottom: 0;
    padding: ${Spacing.XS}px ${Spacing.S}px 0;
`;

export const PageHeaderWrapper = styled(Stack)<{ isFilterActive?: boolean; paddingTop?: number }>`
    position: relative;
    flex-direction: column;
    padding: 0 40px;
    padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : Spacing.M)}px;
    gap: ${Spacing.XS}px;
    margin-bottom: ${Spacing.XS}px;

    @media only screen and (max-width: ${Breakpoints.S}) {
        ${(props) => (props.isFilterActive ? PageHeaderWrapperMobileStyles : "")}
    }

    @media only screen and (max-width: ${Breakpoints.XS}) {
        ${PageHeaderWrapperMobileStyles}
    }
`;

export const TitleWrapper = styled(Stack)<{ isOverlayMode?: boolean }>`
    ${(props) =>
        props.isOverlayMode
            ? `
                @media (min-width: ${Breakpoints.XS}) {
                    position: absolute;
                    top: ${Spacing.M}px;
                    right: 40px;
                }
            `
            : `
                justify-content: space-between;
                align-items: center;

                @media (min-width: ${Breakpoints.XS}) {
                    min-height: 50px;
                }

                @media (min-width: ${Breakpoints.L}) {
                    min-height: 52px;
                }
            `}
`;

export const InsightsSection = styled(Stack)`
    gap: ${Spacing.M}px;

    @media (max-width: ${Breakpoints.XS}) {
        flex-wrap: wrap;
        gap: ${Spacing.S}px;
    }
`;

export const arrowStyles: CSSProperties = {
    position: "absolute",
    zIndex: 2,
    top: 0,
    bottom: 0,
    border: "0px transparent",
    cursor: "pointer",
    background: "linear-gradient(270deg, #011523 27.6%, rgba(1, 21, 35, 0) 100%)",
    right: 0,
    width: 45,
};

export const arrowStylesBack: CSSProperties = {
    position: "absolute",
    zIndex: 2,
    top: 0,
    bottom: 0,
    border: "0px transparent",
    cursor: "pointer",
    background: "linear-gradient(90deg, #011523 27.6%, rgba(1, 21, 35, 0) 100%)",
    left: 0,
    width: 45,
};

export const StyledButtonText = styled(ButtonText)`
    gap: ${Spacing.XS}px;
`;

export const LegendStyled = styled(Stack)`
    row-gap: ${Spacing.M}px;

    @media (max-width: ${Breakpoints.XS}) {
        row-gap: ${Spacing.XS}px;
    }

    @media (min-width: ${Breakpoints.XS}) and (max-width: ${Breakpoints.M}) {
        row-gap: ${Spacing.S}px;
    }
`;
