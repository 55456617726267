import { useSqlQuery } from "src/sql/hooks";

import { getIncidentCountQuery, parseIncidentCountQuery } from "../../IncidentMatrix.queries";

type UseMatrixIncidentCountProps = {
    categories: string[];
    level: number;
    siteIds: string[];
    currentPeriod: { start: Date; end: Date };
    previousPeriod: { start: Date; end: Date };
};

type UseMatrixIncidentCountResult = {
    isLoading: boolean;
    currentPeriodIncidentCount: number;
    previousPeriodIncidentCount: number;
};

export const useMatrixIncidentCount = ({
    categories,
    level,
    siteIds,
    currentPeriod,
    previousPeriod,
}: UseMatrixIncidentCountProps): UseMatrixIncidentCountResult => {
    const { isLoading: isCurrentPeriodLoading, queryResult: currentPeriodIncidentCount } = useSqlQuery(getIncidentCountQuery, parseIncidentCountQuery, {
        startDate: currentPeriod.start,
        endDate: currentPeriod.end,
        categories,
        level,
        siteIds,
    });

    const { isLoading: isPreviousPeriodLoading, queryResult: previousPeriodIncidentCount } = useSqlQuery(getIncidentCountQuery, parseIncidentCountQuery, {
        startDate: previousPeriod.start,
        endDate: previousPeriod.end,
        categories,
        level,
        siteIds,
    });

    const isLoading = isCurrentPeriodLoading || isPreviousPeriodLoading;

    return {
        isLoading,
        currentPeriodIncidentCount: currentPeriodIncidentCount?.totalIncidents ?? 0,
        previousPeriodIncidentCount: previousPeriodIncidentCount?.totalIncidents ?? 0,
    };
};
