import { IconType, Skeleton, Stack } from "@secuis/ccp-react-components";
import { PropsWithChildren, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { EmptyInboxContent } from "src/components/shared/NoContent/EmptyInboxContent";
import { QaProps } from "src/models";
import { CSSProperties } from "styled-components";

import { WidgetBase } from "../WidgetBase";
import { WidgetFooter } from "../WidgetFooter";
import { WidgetHeader } from "../WidgetHeader";
import { IconStyled } from "./Widget.styles";

type Props = PropsWithChildren &
    QaProps & {
        title?: string;
        extraInfo?: string;
        tooltip?: string;
        isLoading?: boolean;
        isEmpty?: boolean;
        footer?: ReactNode;
        titleSection?: ReactNode;
        topRightCornerSection?: ReactNode;
        headerSeparator?: boolean;
        emptyStateIcon?: IconType;
        emptyStateInfo?: string;
        style?: CSSProperties;
        className?: string;
    };

export const Widget = ({
    title,
    tooltip,
    extraInfo,
    headerSeparator,
    children,
    footer,
    emptyStateIcon = "GraphIndicator",
    emptyStateInfo,
    topRightCornerSection,
    titleSection,
    isLoading = false,
    isEmpty = false,
    ...otherProps
}: Props) => {
    const { t } = useTranslation();
    const isContentVisible = !isLoading && !isEmpty;
    const withHeader = !!title || !!titleSection;

    return (
        <WidgetBase {...otherProps}>
            {withHeader && (
                <WidgetHeader title={title} extraInfo={extraInfo} tooltip={tooltip} titleSection={titleSection} separator={headerSeparator}>
                    {topRightCornerSection}
                </WidgetHeader>
            )}

            <Stack flex={1}>
                {isLoading && <Skeleton mode="rectangular" />}
                {!isLoading && isEmpty && (
                    <EmptyInboxContent
                        title={t("common.noDataToDisplay")}
                        subtitle={emptyStateInfo ?? t("common.adjustTheDateAndFilters")}
                        mt="0"
                        icon={<IconStyled variant={emptyStateIcon} />}
                    />
                )}
                {isContentVisible && children}
            </Stack>
            {footer && <WidgetFooter>{footer}</WidgetFooter>}
        </WidgetBase>
    );
};
