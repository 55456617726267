import { Box, Graph, IconType, Palette, Stack } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    DateType,
    differenceInHours,
    differenceInMilliseconds,
    formatDateTime,
    formatDateTimeWithTimezone,
    formatDuration,
    intervalToDuration,
    isAllDatesOfSameDay,
} from "src/helpers/date";
import { DateTimeVariant } from "src/helpers/date/types";
import { CalloutLog, CalloutStatus } from "src/models/ReportModel";

import { findTimeByStatusTag } from "./Timeline.helpers";
import { DefaultItemFieldWrapperStyled, LegendText, LegendTime, StackStyled, TimelineStatusDot } from "./Timeline.styles";
import { TimelineEvent } from "./TimelineEvent";

const DEFAULT_ICON = "MobileGuarding";
const timelineEventsConfig: { [k: string]: { icon: IconType; text: string } } = {
    [CalloutStatus.received]: { icon: "WarningHigh", text: "reportdetails.callout.timeline.calloutReceived" },
    [CalloutStatus.assigned]: { icon: "File", text: "reportdetails.callout.timeline.calloutAssigned" },
    [CalloutStatus.rejected]: { icon: "Error", text: "reportdetails.callout.timeline.calloutRejected" },
    [CalloutStatus.accepted]: { icon: "File", text: "reportdetails.callout.timeline.calloutAccepted" },
    [CalloutStatus.onRoute]: { icon: DEFAULT_ICON, text: "reportdetails.callout.timeline.guardOnRoute" },
    [CalloutStatus.arrivedOnSite]: { icon: DEFAULT_ICON, text: "reportdetails.callout.timeline.guardArrived" },
    [CalloutStatus.finished]: { icon: "File", text: "reportdetails.callout.timeline.calloutFinished" },
    [CalloutStatus.departed]: { icon: "LeaveBuilding", text: "reportdetails.callout.timeline.guardDeparted" },
    [CalloutStatus.closed]: { icon: "File", text: "reportdetails.callout.timeline.calloutClosed" },
    [CalloutStatus.canceled]: { icon: "Error", text: "reportdetails.callout.timeline.calloutCanceled" },
};

type Props = {
    fieldValue: { logs: CalloutLog[] };
    timeZone?: string;
    shouldAutoTranslate?: boolean;
};

export const Timeline = ({ fieldValue, shouldAutoTranslate, timeZone }: Props) => {
    const { t } = useTranslation();
    const { logs = [] } = fieldValue;
    const arrivalTag = useMemo(() => {
        if (logs.some((log) => log.status.tag === CalloutStatus.arrivedOnSite)) {
            return CalloutStatus.arrivedOnSite;
        }
        if (logs.some((log) => log.status.tag === CalloutStatus.accepted)) {
            return CalloutStatus.accepted;
        }
        return null;
    }, [logs]);
    const departureTag = CalloutStatus.departed;
    const isTheSameDay = isAllDatesOfSameDay(logs.filter((l) => l.createdOn).map((l) => new Date(l.createdOn)));

    const formatTimelineDuration = (startStatusTag: CalloutStatus, endStatusTag: CalloutStatus) => {
        const start = findTimeByStatusTag(startStatusTag, logs);
        const end = findTimeByStatusTag(endStatusTag, logs);
        if (start && end) {
            const interval = intervalToDuration({ start: new Date(start), end: new Date(end) });
            const hours = differenceInHours(new Date(end), new Date(start));
            const minutes = formatDuration(interval, ["minutes"]);
            if (hours) {
                return `${t("common.hours", { count: hours })} ${minutes}`;
            }
            return minutes;
        }
        return null;
    };

    const getColor = (statusTag: CalloutStatus) => {
        const arrivalTime = findTimeByStatusTag(arrivalTag, logs);
        const departureTime = findTimeByStatusTag(departureTag, logs);
        const hasArrivalAndDeparture = arrivalTime && departureTime;
        if (!hasArrivalAndDeparture) {
            return Graph.dataSeries1;
        }
        const time = findTimeByStatusTag(statusTag, logs);
        const isAfterArrival = differenceInMilliseconds(new Date(time), new Date(arrivalTime)) >= 0;
        const isBeforeDeparture = differenceInMilliseconds(new Date(time), new Date(departureTime)) < 0;
        const isAfterDeparture = differenceInMilliseconds(new Date(time), new Date(departureTime)) >= 0;
        if (isAfterDeparture) {
            return Palette.Navy300;
        }
        if (isAfterArrival && isBeforeDeparture) {
            return Graph.dataSeries2;
        }
        return Graph.dataSeries1;
    };
    const timeVariant = isTheSameDay ? "shortTime" : "midDateTime";
    const formatEventDateTime = (date: DateType, variant: DateTimeVariant) => {
        return timeZone ? formatDateTimeWithTimezone(date, timeZone, timeVariant) : formatDateTime(date, variant);
    };
    const responseTime = formatTimelineDuration(CalloutStatus.received, CalloutStatus.arrivedOnSite);
    const timeOneSite = formatTimelineDuration(CalloutStatus.arrivedOnSite, CalloutStatus.departed);

    return (
        <DefaultItemFieldWrapperStyled>
            <StackStyled>
                {logs.length ? (
                    <>
                        <Box>
                            {logs.map((x, index) => {
                                const isLast = logs.length - 1 === index;
                                const config = timelineEventsConfig[x.status.tag];
                                return (
                                    <TimelineEvent
                                        key={x.id}
                                        iconType={config?.icon || DEFAULT_ICON}
                                        status={config?.text || x.status.name}
                                        dateTime={x.createdOn && formatEventDateTime(new Date(x.createdOn), timeVariant)}
                                        lineColor={isLast ? null : getColor(x.status.tag)}
                                        shouldAutoTranslate={shouldAutoTranslate && !config?.text}
                                    />
                                );
                            })}
                        </Box>
                        <Stack direction="column" gap="XS">
                            {responseTime && timeOneSite && (
                                <Stack>
                                    <Stack alignItems="center" gap="XS">
                                        <TimelineStatusDot background={Graph.dataSeries1} />
                                        <LegendText>{t("reportdetails.callout.responseTime")}:</LegendText>
                                    </Stack>
                                    <LegendTime bold>{responseTime}</LegendTime>
                                </Stack>
                            )}
                            {timeOneSite && (
                                <Stack>
                                    <Stack alignItems="center" gap="XS">
                                        <TimelineStatusDot background={Graph.dataSeries2} />
                                        <LegendText>{t("reportdetails.callout.timeOneSite")}:</LegendText>
                                    </Stack>
                                    <LegendTime bold>{timeOneSite}</LegendTime>
                                </Stack>
                            )}
                        </Stack>
                    </>
                ) : (
                    t("common.noDataFound")
                )}
            </StackStyled>
        </DefaultItemFieldWrapperStyled>
    );
};
