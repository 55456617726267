import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useSqlQuery } from "../../../../sql/hooks";
import { useFilteredSites } from "../../../../store/insights/FilterHooks";
import { useAuthorizedLocations } from "../../../../store/locations/LocationsHooks";
import { useSummaryPeriod } from "../shared/hooks";
import { StatisticData } from "../shared/types";
import { getReportsCountQuery, parseReportsCountResult } from "./ReportsCountWidget.queries";

export const useReportsCountWidget = () => {
    const { t } = useTranslation();
    const { siteObjects } = useAuthorizedLocations();
    const { siteIds } = useFilteredSites();
    const selectedSitesCount = siteIds.length ?? siteObjects?.length ?? 0;
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const { reportsCountData: currentPeriodValues, isLoading: isLoadingCurrent } = useReportsCountQuery(currentPeriod.start, currentPeriod.end);
    const { reportsCountData: previousPeriodValues, isLoading: isLoadingPrevious } = useReportsCountQuery(previousPeriod.start, previousPeriod.end);
    const isLoading = isLoadingCurrent || isLoadingPrevious;

    const sitesWithoutReportsCount = {
        current: selectedSitesCount < currentPeriodValues.sitesCount ? 0 : selectedSitesCount - currentPeriodValues.sitesCount,
        previous: selectedSitesCount < previousPeriodValues.sitesCount ? 0 : selectedSitesCount - previousPeriodValues.sitesCount,
    };

    const countStats: StatisticData[] = [
        {
            id: "reports-count",
            title: t("insights.summary.reportsCountWidget.reportsCount"),
            label: t("common.reports", { count: currentPeriodValues.reportsCount }),
            value: currentPeriodValues.reportsCount,
            previousValue: previousPeriodValues.reportsCount,
        },
        {
            id: "sites-with-reports",
            title: t("insights.summary.reportsCountWidget.sitesWithReports"),
            label: t("common.sites", { count: currentPeriodValues.sitesCount }),
            value: currentPeriodValues.sitesCount,
            previousValue: previousPeriodValues.sitesCount,
        },
        {
            id: "sites-without-reports",
            title: t("insights.summary.reportsCountWidget.sitesWithoutReports"),
            label: t("common.sites", { count: sitesWithoutReportsCount.current }),
            value: sitesWithoutReportsCount.current,
            previousValue: sitesWithoutReportsCount.previous,
        },
    ];

    return { isLoading, countStats };
};

const useReportsCountQuery = (startDate: Date, endDate: Date) => {
    const { siteIds } = useFilteredSites();
    const queryParams = useMemo(() => ({ siteIds, startDate, endDate }), [siteIds, startDate, endDate]);
    const { isLoading, queryResult } = useSqlQuery(getReportsCountQuery, parseReportsCountResult, queryParams);

    return { reportsCountData: queryResult ?? { reportsCount: 0, sitesCount: 0 }, isLoading };
};
