import { Icon, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { TooltipRich } from "src/components/shared/TooltipRich";
import styled from "styled-components";

import { REPORT_CATEGORY_ICON_MAP } from "../../../shared/constants";

const StackStyled = styled(Stack)`
    width: 480px;
`;

type HeaderTooltipProps = {
    anchorSelect: string;
    categoryLevel: number;
    categories: string[];
};

export const HeaderTooltip = ({ anchorSelect, categoryLevel, categories }: HeaderTooltipProps) => {
    const { t } = useTranslation();

    return (
        <TooltipRich anchorSelect={anchorSelect} place={categoryLevel === 1 ? "right" : "top"}>
            <StackStyled direction="column" gap="XS">
                {categoryLevel === 1 && (
                    <>
                        <Text small bold>
                            {t("incidentMatrix.header.tooltip.level1.text")}
                        </Text>

                        <Text bold>{t("incidentMatrix.header.tooltip.eventCategories")}</Text>

                        {categories.map((category) => (
                            <Stack key={category} direction="row" gap="XS" alignItems="center">
                                <Icon color="dark" size="S" variant={REPORT_CATEGORY_ICON_MAP[category]} />{" "}
                                <Text small bold>
                                    {t(`incident.category.${category}`)}
                                </Text>
                            </Stack>
                        ))}
                    </>
                )}

                {categoryLevel === 2 && (
                    <Text small bold>
                        {t("incidentMatrix.header.tooltip.level2.text")}
                    </Text>
                )}

                {categoryLevel === 3 && (
                    <Text small bold>
                        {t("incidentMatrix.header.tooltip.level3.text")}
                    </Text>
                )}
            </StackStyled>
        </TooltipRich>
    );
};
