import { Breakpoints, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { DefaultItemFieldWrapper } from "../DefaultItemField";

const CONTAINER_WIDTH = "512px";

export const TimelineStatusDot = styled.div<{ background: string }>`
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: ${({ background }) => background};
`;

export const StackStyled = styled(Stack).attrs({
    justifyContent: "space-between",
})`
    padding: ${Spacing.XS}px 0;
    row-gap: ${Spacing.XS}px;

    @container DefaultItemFieldWrapper (max-width: ${CONTAINER_WIDTH}) {
        flex-direction: column;
        row-gap: ${Spacing.M}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        flex-direction: column;
        row-gap: ${Spacing.M}px;
    }
`;

export const LegendText = styled(Text).attrs({
    truncate: true,
    micro: true,
})`
    width: 120px;
`;

export const LegendTime = styled(LegendText).attrs({
    bold: true,
})`
    margin-left: ${Spacing.XXS}px;

    @media (min-width: ${Breakpoints.XS}) and (max-width: ${Breakpoints.L}) {
        width: 100px;
    }
`;

export const DefaultItemFieldWrapperStyled = styled(DefaultItemFieldWrapper)`
    margin-bottom: 0%;
`;
