import { Button, ButtonText, Headline, Stack } from "@secuis/ccp-react-components";
import { addDays, format, subDays } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { endOfDay, formatExplicit, RAW_DATE_FORMAT, startOfDay } from "src/helpers/date";
import AccessSelectors from "src/store/access/AccessSelectors";
import { useLazyFetchReportsFromSearchQuery } from "src/store/reports/reportsApi";
import { initApiMock } from "src/store/schedule/mockSetup";

import { featureFlags } from "../../data/featureFlags";
import { useFeatureFlag } from "../../hooks/featureFlags";
import { DateInput } from "../shared/filters";

type Props = {
    locationId: string;
    date: Date;
    setDate: (d: Date) => void;
    getTasks: (args: { date: string; locationId: string }) => void;
};

export const ScheduleFilters = ({ locationId, getTasks, date, setDate }: Props) => {
    const { t } = useTranslation();
    // used by api mock
    const userId = useSelector(AccessSelectors.getUserId);
    const [isWorkerActive, setIsWorkerActive] = useState(false);
    const [fetchReportsFromSearch] = useLazyFetchReportsFromSearchQuery();
    const areSitesTimezonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);

    useEffect(() => {
        const requestTasks = async () => {
            // Temp api mock.
            // TODO: Remove after BE implemented.
            if (!isWorkerActive) {
                const tourSessions = await fetchReportsFromSearch({
                    key: "searchKey",
                    useSiteTimezone: areSitesTimezonesEnabled,
                    body: {
                        locationIds: [locationId],
                        userId: userId,
                        reportCategories1: [],
                        reportCategories2: [],
                        reportCategories3: [],
                        reportTypes: ["tourSession"],
                        excludedReportTypes: [],
                        siteLocations: [],
                        severityLevels: [],
                        from: startOfDay(new Date("2023-12-14")),
                        to: endOfDay(new Date("2024-06-14")),
                        isReadReport: null,
                    },
                    pageSize: 20,
                    pageNumber: 0,
                    timestampRequested: Date.now(),
                });
                await initApiMock(tourSessions["data"]?.reports || []);
                setIsWorkerActive(true);
            }
            const isoDate = format(date, RAW_DATE_FORMAT);
            getTasks({ date: isoDate, locationId });
        };
        requestTasks();
    }, [locationId, getTasks, date, isWorkerActive]);

    const handleDateChange = useCallback((startDate: Date) => {
        setDate(startDate);
    }, []);

    const setTodaysDate = useCallback(() => {
        setDate(new Date());
    }, []);

    const setPreviousDate = useCallback(() => {
        setDate(subDays(date, 1));
    }, [date]);

    const setNextDate = useCallback(() => {
        setDate(addDays(date, 1));
    }, [date]);

    return (
        <Stack marginVertical="S" flexWrap="wrap" gap={["M", "S", "XS"]}>
            <Button mode="outlined" fitContent onClick={setTodaysDate}>
                {t("common.date.today")}
            </Button>
            <Stack flex="1" alignItems="center" gap={["S", "S", "XS"]}>
                <ButtonText onClick={setPreviousDate} icon="ArrowBack" iconSize="XL" />
                <ButtonText onClick={setNextDate} icon="ArrowForward" iconSize="XL" />
                <Headline bold>{formatExplicit(date, "eee, PPP")}</Headline>
            </Stack>
            <Stack marginTop={["0", "0", "XS"]}>
                <DateInput
                    placeholder={t("datepicker.placeholder")}
                    onDateChange={handleDateChange}
                    selectedStartDate={date}
                    selectedEndDate={date}
                    mode="singleMonth"
                    isDaySelection
                />
            </Stack>
        </Stack>
    );
};
